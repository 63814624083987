import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import { authModule } from "./auth";
import { companyModule } from "./company";
import { userModule } from "./user";
import { leadModule } from "./lead";
import { invoiceModule } from "./invoice";
import { allTaskModule } from "./filters/allTask"
import { boardModule } from "./filters/board"
import { globalTrackerModule } from "./filters/globalTracker"
import { superAdminAuthModule } from "./super-admin/auth";
import { paymentMethodModule } from "./payment";

import createPersistedState from "vuex-persistedstate";
import { ls } from "@/helpers/helpers";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.DEV,
  modules: {
    app,
    appConfig,
    verticalMenu,
    authModule,
    userModule,
    leadModule,
    invoiceModule,
    boardModule,
    globalTrackerModule,
    allTaskModule,
    superAdminAuthModule,
    paymentMethodModule,
    companyModule,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
