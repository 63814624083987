import {
  SUBSCRIPTION_ACCESS,
  PRICING_TABLE_ACCESS,
  SUBSCRIPTION_EDIT,
  PAYMENT_HISTORY_ACCESS,
} from "@/helpers/permissionsConstant";
import store from "@/store";
const paymentMethod = store.getters["paymentMethodModule/getPaymentMethod"];

const stripePages = [
  {
    path: "/pricing",
    name: "pricing-table",
    component: () => import("@/views/admin/payment/stripe/Subscription.vue"),
    meta: {
      gate: PRICING_TABLE_ACCESS,
      requiresAuth: true,
      pageTitle: "Pricing Tables",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Pricing Tables",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/payment-histories",
    name: "payment-histories",
    component: () =>
      import("@/views/admin/payment/stripe/PaymentHistoriesView.vue"),
    meta: {
      gate: PAYMENT_HISTORY_ACCESS,
      requiresAuth: true,
      pageTitle: "Payment Histories",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Payment Histories",
          active: true,
          to: "",
        },
      ],
    },
  },
];


const sslCommerzPages = [
  {
    path: "/pricing",
    name: "pricing-table",
    component: () => import("@/views/admin/payment/sslCommerz/Subscription.vue"),
    meta: {
      gate: PRICING_TABLE_ACCESS,
      requiresAuth: true,
      pageTitle: "Pricing Tables",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Pricing Tables",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/payment-histories",
    name: "payment-histories",
    component: () =>
      import("@/views/admin/payment/sslCommerz/PaymentHistoriesView.vue"),
    meta: {
      gate: PAYMENT_HISTORY_ACCESS,
      requiresAuth: true,
      pageTitle: "Payment Histories",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Payment Histories",
          active: true,
          to: "",
        },
      ],
    },
  },
];



const manualPaymentPages = [
  {
    path: "/pricing",
    name: "pricing-table",
    component: () => import("@/views/admin/payment/manual-payment/Subscription.vue"),
    meta: {
      gate: PRICING_TABLE_ACCESS,
      requiresAuth: true,
      pageTitle: "Pricing Tables",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Pricing Tables",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/payment-histories",
    name: "payment-histories",
    component: () =>
      import("@/views/admin/payment/manual-payment/PaymentHistoriesView.vue"),
    meta: {
      gate: PAYMENT_HISTORY_ACCESS,
      requiresAuth: true,
      pageTitle: "Payment Histories",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Payment Histories",
          active: true,
          to: "",
        },
      ],
    },
  },
];

let filteredPages = [];

if (paymentMethod === 1) {
  filteredPages = stripePages;
} else if (paymentMethod === 2) {
  filteredPages = sslCommerzPages;
} else if (paymentMethod === 3) {
  filteredPages = manualPaymentPages;
}

export default [
  ...filteredPages,
];
