
export default [
    {
        path: "/super-admin/company-request",
        name: "company-request",
        component: () => import("@/views/super-admin/company-request/CompanyRequestView.vue"),
        meta: {
          requiresSuperAdmin: true,
          pageTitle: "Company Request",
        },
      },
  ];
  