let pusherChannel = "";
let pusherAppKey = "";
if (process.env.VUE_APP_ENCRYPTION_NAMESPACE === "vuex-my-prod") {
  pusherChannel = "projectx-development";
  pusherAppKey = "8e7c2bd79ed423c47016";
} else if (
  process.env.VUE_APP_ENCRYPTION_NAMESPACE === "vuex-projectx-prod-dev"
) {
  pusherChannel = "projectx-staging";
  pusherAppKey = "61df9474a50f281cf9d3";
} else if (
  process.env.VUE_APP_ENCRYPTION_NAMESPACE === "vuex-projectx-prod-zone"
) {
  pusherChannel = "projectx-production";
  pusherAppKey = "c164631f813e0f8d343b";
}

export { pusherChannel, pusherAppKey };
