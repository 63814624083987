// import { permissionsConstant } from "@/helpers/permissionsConstant";

export default [
    {
      path: "/super-admin-dashboard",
      name: "super-admin-dashboard",
      component: () => import("@/views/super-admin/dashboard/SuperAdminDashboardView.vue"),
      meta: {
        pageTitle: "Super Admin Dashboard",
        requiresSuperAdmin: true,
      },
    },
  ];
  