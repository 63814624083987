export const paymentMethodModule = {
  namespaced: true,
  state: {
    paymentMethod: null,
  },
  getters: {
    getPaymentMethod: (state) => state.paymentMethod,
  },
  mutations: {
    setPaymentMethod: (state, payload) => {
      state.paymentMethod = payload.paymentMethod;
    },
  },
  actions: {
    setPaymentMethod: ({ commit }, payload) => {
      commit("setPaymentMethod", {
        paymentMethod: payload.paymentMethod,
      });
    },
  },
};
