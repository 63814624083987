// import { permissionsConstant } from "@/helpers/permissionsConstant";

export default [
  {
    path: "/super-admin-login",
    name: "super-admin-login",
    component: () => import("@/views/super-admin/auth/LoginView.vue"),
    meta: {
      pageTitle: "Super Admin Login",
      requiresAuth: false,
      requiresVisitor: true,
      layout: "full",
    },
  }
];
