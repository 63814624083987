
export default [
    {
        path: "/super-admin/company",
        name: "company-management",
        component: () => import("@/views/super-admin/company/CompanyView.vue"),
        meta: {
          requiresSuperAdmin: true,
          pageTitle: "Company Management",
        },
    },
    {
        path: "/super-admin/company/:payment_method/:id",
        name: "company-subscription-details",
        component: () => import("@/views/super-admin/company/CompanySubscriptionHistoryView.vue"),
        meta: {
          requiresSuperAdmin: true,
          pageTitle: "Company Subscription History",
        },
    },
  ];
  