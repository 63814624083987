export const superAdminAuthModule = {
  namespaced: true,
  state: {
    isAuthenticated: !!localStorage.getItem("superAdminToken"),
    token: localStorage.getItem("superAdminToken") || null,
    user: JSON.parse(localStorage.getItem("superAdminUser")) || null, // Store user data
  },
  getters: {
    getIsAuthenticated: (state) => state.isAuthenticated,
    getToken: (state) => state.token,
    getUser: (state) => state.user || "",
  },
  mutations: {
    setIsAuthenticated: (state, payload) => {
      state.isAuthenticated = payload.isAuthenticated;
      state.token = payload.token;
      state.user = payload.user;
      
      localStorage.setItem("superAdminToken", payload.token);
      localStorage.setItem("superAdminUser", JSON.stringify(payload.user));
    },
    clearPersistedState: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.user = null;

      localStorage.removeItem("superAdminToken");
      localStorage.removeItem("superAdminUser");
    },
  },
  actions: {
    setIsAuthenticated: ({ commit }, payload) => {
      commit("setIsAuthenticated", payload);
    },
    clearPersistedState: ({ commit }) => {
      commit("clearPersistedState");
    },
  },
};
