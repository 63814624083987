import instance from '@/helpers/axios';

export const companyModule = {
  namespaced: true,
  state: {
    data: {
      name: '',
      email: '',
      address: '',
    },
  },
  getters: {
    data: (state) => state.data, // ✅ Correctly placed inside `getters`
  },
  mutations: {
    setData: (state, payload) => {
      state.data = payload;
    },
  },
  actions: {
    async fetchCompanyData({ state, commit }) {
      if (state.data.name) {
        return state.data
      }

      try {
        const company = await instance.get("/api/companies/current");
        const data = company?.data?.data
        commit('setData', data)
        return data;
      } catch (error) {
        console.error('Error fetching company data:', error);
        throw error;
      }

    },
  },
};
