var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "h-100",
    attrs: {
      "id": "app"
    }
  }, [_vm.isAuth ? [_c('Intro')] : _vm._e(), _vm.isSuperAdmin ? [_c('LayoutSuperAdmin')] : _vm._e(), !_vm.isSuperAdmin ? _c(_vm.layout, {
    tag: "component"
  }, [_c('router-view')], 1) : _vm._e(), _vm.isAuth && _vm.$route.path != '/' ? [_c('GlobalAddTaskModal')] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }