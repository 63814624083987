import {
  SALARY_POLICY_ACCESS,
  PROJECT_COMMISSION_POLICY_ACCESS,
  OVER_TIME_POLICY_ACCESS,
  INSURANCE_POLICY_ACCESS,
  INCOME_TAX_POLICY_ACCESS,
  FESTIVAL_BONUS_POLICY_ACCESS,
  DELAY_FINE_POLICY_ACCESS,
  FINE_POLICY_ACCESS,
  MEAL_POLICY_ACCESS,
  SALARY_PAYSLIP_ACCESS,
  INCOME_TAX_POLICY_SHOW,
  SALARY_PAYSLIP_SHOW,
  PAYROLL_MANAGEMENT_ACCESS,
  EXTRA_LEAVE_POLICY_ACCESS,
  PROVIDENT_FUND_POLICY_ACCESS,
  OUTREACH_SELLER_ACCESS,
  NICHE_ACCESS,
  SITE_LIST_ACCESS,
  BACK_LINK_ACCESS,
  OUTREACH_INVOICE_ACCESS,
  OUTREACH_LOGS_ACCESS,
  OUTREACH_INVOICE_SHOW,
} from "@/helpers/permissionsConstant";
export default [
  {
    path: "/outreach/outreach-seller",
    name: "outreach-seller",
    component: () =>
      import("@/views/admin/outreach-management/OutreachSellerView.vue"),
    meta: {
      gate: OUTREACH_SELLER_ACCESS,
      requiresAuth: true,
      pageTitle: "Seller",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Outreach",
          active: true,
          to: "",
        },
        {
          text: "Seller",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/outreach/outreach-niche",
    name: "outreach-niche",
    component: () =>
      import("@/views/admin/outreach-management/OutreachNicheView.vue"),
    meta: {
      gate: NICHE_ACCESS,
      requiresAuth: true,
      pageTitle: "Niche",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Outreach",
          active: true,
          to: "",
        },
        {
          text: "Niche",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/outreach/outreach-approvefor-niche",
    name: "outreach-approvefor-niche",
    component: () =>
      import(
        "@/views/admin/outreach-management/OutreachApproveforNicheView.vue"
      ),
    meta: {
      gate: NICHE_ACCESS,
      requiresAuth: true,
      pageTitle: "Approve For Niche",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Outreach",
          active: true,
          to: "",
        },
        {
          text: "Approve For Niche",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/outreach/site-list",
    name: "site-list",
    component: () =>
      import("@/views/admin/outreach-management/SiteListView.vue"),
    meta: {
      gate: SITE_LIST_ACCESS,
      requiresAuth: true,
      pageTitle: "Site List",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Outreach",
          active: true,
          to: "",
        },
        {
          text: "Site List",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/outreach/back-link",
    name: "back-link",
    component: () =>
      import("@/views/admin/outreach-management/BackLinkView.vue"),
    meta: {
      gate: BACK_LINK_ACCESS,
      requiresAuth: true,
      pageTitle: "Backlinks",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Outreach",
          active: true,
          to: "",
        },
        {
          text: "Backlinks",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/outreach/outreach-invoice-list",
    name: "outreach-invoice-list",
    component: () =>
      import("@/views/admin/outreach-management/OutreachInvoiceView.vue"),
    meta: {
      gate: OUTREACH_INVOICE_ACCESS,
      requiresAuth: true,
      pageTitle: "Invoice",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Outreach",
          active: true,
          to: "",
        },
        {
          text: "Invoice",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/outreach/outreach-invoice-details/:id",
    name: "outreach-invoice-details",
    component: () =>
      import(
        "@/views/admin/outreach-management/OutreachInvoiceDetailsView.vue"
      ),
    meta: {
      gate: OUTREACH_INVOICE_SHOW,
      requiresAuth: true,
      pageTitle: "Outreach Invoice Details",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Outreach",
          active: true,
          to: "",
        },
      ],
    },
  },
  {
    path: "/outreach/logs",
    name: "outreach-logs",
    component: () =>
      import("@/views/admin/outreach-management/OutreachLogsView.vue"),
    meta: {
      gate: OUTREACH_LOGS_ACCESS,
      requiresAuth: true,
      pageTitle: "Outreach Logs",
      homePatch: "/admin/dashboard",
      breadcrumb: [
        {
          text: "Outreach",
          active: true,
          to: "",
        },
      ],
    },
  },
];
