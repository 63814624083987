import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { permissionAbility } from "@/helpers/helpers";
import superAdminAuthRouter from "./superAdminAuth/authRouter";
import adminAuthRouter from "./adminAuth/authRouter";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...superAdminAuthRouter,
    ...adminAuthRouter,
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-403",
      name: "error-403",
      component: () => import("@/views/error/Error403.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// global auth guard
router.beforeEach((to, from, next) => {
    const isAdmin = store.getters["authModule/getIsAuthenticated"];
    const isSuperAdmin = store.getters["superAdminAuthModule/getIsAuthenticated"];
  
    // If route requires authentication but user is not logged in, redirect to login
    if (to.meta.requiresAuth && !isAdmin) {
      return next({ path: "/login", query: { redirect: to.fullPath } });
    }
  
    // Restrict routes based on user role
    if (to.meta.requiresSuperAdmin && !isSuperAdmin) {
      return next("/error-403"); // Forbidden for non-super-admins
    }
  
    // Check permission ability (if required for certain routes)
    if (to.meta.permission && !permissionAbility(to.meta.permission)) {
      return next("/error-403");
    }
  
    next(); // Allow navigation
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
