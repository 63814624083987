import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import superAdminDashboard from "./dashboardRouter";
import companyRouter from "./companyRouter";
import companyRequestRouter from "./companyRequestRouter";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...superAdminDashboard,
    ...companyRouter,
    ...companyRequestRouter,
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/error-403",
      name: "error-403",
      component: () => import("@/views/error/Error403.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, from, next) => {
    const isSuperAdmin = store.getters["superAdminAuthModule/getIsAuthenticated"];
    // Check if the user is authenticated
    if (to.meta.requiresSuperAdmin && !isSuperAdmin) {
      return next({ path: "/super-admin-login", query: { redirect: to.fullPath } });
    }
  
    next(); // Allow navigation
});
  

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
